import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function ExtractTextPage() {
  const [pdfPreview, setPdfPreview] = useState(null);
  const [extractedText, setExtractedText] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { fileURL } = location.state || {};

  useEffect(() => {
    if (!fileURL) {
      navigate('/');
    } else {
      setPdfPreview(fileURL);
    }
  }, [fileURL, navigate]);

  const handleExtractText = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://oiul0qjmfl.execute-api.us-east-1.amazonaws.com/dev/extract-text', { 
        bucket: 'pdfextracttools', 
        key: fileURL.split('/').pop() 
      });
      const { text, outputKey } = response.data; // Asegúrate de devolver outputKey desde tu Lambda
      setExtractedText(text);
      navigate('/downloadtextpage', { state: { outputKey } });
    } catch (error) {
      console.error('Error al extraer el texto:', error);
      alert('Hubo un error al extraer el texto.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {pdfPreview && (
        <div>
          <h2>Previsualización del PDF</h2>
          <object data={pdfPreview} type="application/pdf" width="100%" height="500px">
            <p>El archivo PDF no se puede mostrar en este navegador. Puedes <a href={pdfPreview}>descargarlo aquí</a>.</p>
          </object>
          <button onClick={handleExtractText} disabled={loading}>
            {loading ? 'Extrayendo texto...' : 'Extraer texto'}
          </button>
        </div>
      )}
      {extractedText && (
        <div>
          <h2>Texto Extraído</h2>
          <pre>{extractedText}</pre>
        </div>
      )}
    </div>
  );
}

export default ExtractTextPage;




























