import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports.js';
import { Amplify } from 'aws-amplify';

Amplify.configure(awsconfig);

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // Agrega estado para el mensaje de error
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Evita que el formulario se envíe automáticamente

    console.log('Entrando en handleLogin');
    console.log('Email:', email);
    console.log('Password:', password);

    try {
      await Auth.signIn(email, password); // Inicia sesión con AWS Cognito
      console.log('Inicio de sesión exitoso');
      console.log('Usuario registrado:', email);
      // El inicio de sesión fue exitoso, redirige al usuario a una página de bienvenida, por ejemplo.
      navigate('/PaymentGateway');
    } catch (error) {
      console.error('Error de inicio de sesión:', error);
      console.log('Código de error:', error.code);
      console.log('Mensaje de error:', error.message);
      setError(error.message); // Almacena el mensaje de error en el estado
    }
  };

  return (
    <div className="container">
      <h2>Iniciar Sesión</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="button">
          Iniciar Sesión
        </button>
      </form>
      {error && <p className="error-message">{error}</p>} {/* Muestra el mensaje de error si existe */}
      <br />
      <br />
      <p1>
        ¿No tienes una cuenta? <a href="/register">Regístrate</a>
      </p1>
      
    </div>
  );
}

export default Login;






  