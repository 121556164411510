import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';

function HomePage() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      alert('Por favor seleccione un archivo.');
      return;
    }

    setUploading(true);

    AWS.config.update({
      accessKeyId: 'AKIA42J7OELPQI5KTI4B', // Reemplaza con tu accessKeyId
      secretAccessKey: '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', // Reemplaza con tu secretAccessKey
      region: 'us-east-1',
    });

    const s3 = new AWS.S3();

    const params = {
      Bucket: 'pdfextracttools',
      Key: selectedFile.name,
      Body: selectedFile,
      ContentType: selectedFile.type,
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error('Error al cargar el archivo a S3:', err);
        alert('Hubo un error al cargar el archivo.');
      } else {
        console.log('Archivo cargado con éxito:', data);
        navigate('/extracttextpage', { state: { fileURL: data.Location } });
      }
      setUploading(false);
    });
  };

  return (
    <div>
      <input type="file" onChange={handleFileSelect} />
      <button onClick={handleUpload} disabled={uploading}>
        {uploading ? 'Cargando...' : 'Subir archivo'}
      </button>
      <Link to="/login">Iniciar sesión / Registro</Link>
    </div>
  );
}

export default HomePage;















