import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: 'us-east-2_wflCLv0DE',
  ClientId: '6b2k2q8upgse1ur4fjkf0la2r7',
};

const userPool = new CognitoUserPool(poolData);

export default userPool;

