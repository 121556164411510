import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function DownloadTextPage() {
  const location = useLocation();
  const { outputKey } = location.state || {};
  const navigate = useNavigate();

  const handleDownload = () => {
    const s3Url = `https://pdfextracttools.s3.amazonaws.com/${outputKey}`;
    const a = document.createElement('a');
    a.href = s3Url;
    a.download = outputKey.split('/').pop(); // Nombre del archivo descargado
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(s3Url);

    // Después de la descarga exitosa, redirigir a la página principal
    navigate('/');
  };

  return (
    <div>
      <h1>¡Ya puedes descargar el texto extraído de tu PDF!</h1>
      <button onClick={handleDownload}>Descargar Texto</button>
    </div>
  );
}

export default DownloadTextPage;
