import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logoImage from './pdf-escaneado-a-texto-pdf-to-text-image.jpeg';

function PaymentGateway() {
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('efectivo');
  const navigate = useNavigate(); // Utiliza useNavigate en v6

  const handleCashReload = () => {
    // Lógica para procesar la recarga en efectivo
    // Asegúrate de validar la cantidad ingresada y registrar la transacción
  };

  const handleExit = () => {
    // Lógica adicional antes de salir si es necesario
    // Por ejemplo, limpiar el estado o realizar alguna acción antes de salir
    navigate('/salida'); // Navegar a la página de salida
  };

  return (
    <div className="payment-container">
      <img src={logoImage} alt="PDF to Text Logo" className="logo1" />
      <div className="payment-method">
        <br />
        <br />
        <br />
        <h3>!Gracias por pertenecer a nuestra vibrante comunidad¡</h3>
        <br />
        <br />
        <br />
        <h3>Ahora recibirás actualizaciones en tu correo sobre nuevos servicios </h3>
        <h3> y mejoras en el algoritmo con el que trabajamos</h3>
        <div className="method-options">
          {/* ... (opciones de método de pago) */}
        </div>
      </div>
        <br />
        <br />
        <br />
        <br />
        
      <button className="salir-button" onClick={handleExit}>
        volver al inicio
      </button>
    </div>
  );
}

export default PaymentGateway;



          
