import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

const VerificationPage = () => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !verificationCode) {
      console.error('Email or verification code is empty');
      return;
    }

    try {
      console.log('Confirming registration for email:', email);

      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: 'us-east-1_fDoH6sE7F',
        ClientId: '17tl16o91rvf3ip956sulc2gnm',
      });

      const userData = {
        Username: email,
        Pool: userPool,
      };

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
        if (err) {
          console.error('Error confirming registration:', err);
          return;
        }

        console.log('Registration confirmed successfully:', result);
        navigate('/login');
      });
    } catch (error) {
      console.error('Error confirming registration:', error);
    }
  };

  return (
    <div>
      <h2>Email Verification</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="verification-code">Verification Code:</label>
        <input
          type="text"
          id="verification-code"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          required
        />
        <button type="submit">Verify</button>
      </form>
    </div>
  );
};

export default VerificationPage;



























